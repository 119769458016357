<template>
    <div>
        <Header headerType="advance_result"/> 


        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '') ">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <h1>{{ count }}</h1>
                            <h2>{{$t('Result')}}</h2> 
                        </div>
                    </div>
                    <div class="advance box-fix-left" ref="menuFlag"  style="top:0px;">
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        Prix
                                    </p>
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">   
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" class="btn btn-orange pointerNone">{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>

                    <TablePrix />  
 
                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import TablePrix from '@/components/advanced_result/table_prix.vue';
import { eventHandlerResize, isUserFrench } from '@/utils';
import Loader from "@/components/Layout/Loader";
export default {
    name: 'advanced_result_prix',
    components: {        
        Header,
        Footer,
        TablePrix,
        Loader           
    },
    data() {
        return {
            
        }
    },
    mounted() {
        document.body.classList.add('body-bg-gray') 
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }
    },
    methods: { 
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
        eventHandlerResize,
    },
    computed: {
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        isLoad() {               
            return this.$store.getters['advanced_result/prix_load'] 
        },
        count() {
            return this.$store.getters['advanced_result/prix_count']
        }, 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },
    },
    created() {
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        console.log('unmounted prix')
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("advanced_result/clearStatePrix") 
        
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
}
.box-fix-left{ padding-top:133px}
/* CSS for fixed height of table for show in one page
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
} */
</style>